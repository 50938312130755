import "./App.css";
import {
  HeartOutlined,
  SearchOutlined,
  HomeOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
  SendOutlined,
  UserOutlined,
  MessageOutlined,
  CommentOutlined,
  ShareAltOutlined,
  HeartFilled,
} from "@ant-design/icons";
import {
  Image,
  Avatar,
  Space,
  Checkbox,
  Form,
  Input,
  Typography,
  Divider,
  Comment,
  Tooltip,
  Modal,
  Button,
  Radio,
  List,
  notification,
  message,
  Badge,
  Select,
} from "antd";
import moment from "moment";
import { useRef, useState, useEffect } from "react";
const { Title, Text, Link } = Typography;
const { Option } = Select;

notification.config({
  top: 50,
  duration: 4,
});

message.config({
  top: 50,
  duration: 2,
});

const MIN_AI_TIME = 20000;

const AVATARS = [
  "clown-face_1f921.png",
  "cold-face_1f976.png",
  "cowboy-hat-face_1f920.png",
  "disguised-face_1f978.png",
  "face-with-hand-over-mouth_1f92d.png",
  "horse-racing_medium-light-skin-tone_1f3c7-1f3fc_1f3fc.png",
  "nerd-face_1f913.png",
  "performing-arts_1f3ad.png",
  "robot_1f916.png",
  "santa-claus_light-skin-tone_1f385-1f3fb_1f3fb.png",
  "unicorn_1f984.png",
  "woman-light-skin-tone-red-hair_1f469-1f3fb-200d-1f9b0.png",
  "woman-medium-skin-tone-beard_1f9d4-1f3fd-200d-2640-fe0f.png",
];

const IMAGES = [
  "Biene.jpg",
  "Frau Gitarre.jpg",
  "Frau und Kind.jpg",
  "Katze.jpg",
  "Kinder Fußball.jpg",
  "Mann Frisbee.jpg",
  "Nachtschaukel.jpg",
  "Porsche.jpg",
  "Riesenrad.jpg",
];

const IMAGES_BOT = [
  {
    image: "1.jpg",
    description: "Möwe beim stoßtauchen geknipst!",
  },
  {
    image: "2.jpg",
    description: "Absolut monströses Landschaftsbild",
  },
  {
    image: "3.jpg",
    description: "#Love #LoveYourself",
  },
  {
    image: "4.jpg",
    description: "#running #fit # healthy",
  },
  {
    image: "5.jpg",
    description: "Wunderbarer Samstag mit Picknick im Freien",
  },
  {
    image: "6.jpg",
    description: "Kreatives Schaffen bei der CBB",
  },
  {
    image: "7.jpg",
    description: "Silhouette im Sonnenuntergang #pretty",
  },
  {
    image: "8.jpg",
    description: "Herbst ist meine Lieblingsjahreszeit - einfach hübsch",
  },
  {
    image: "9.jpg",
    description:
      "Habe einen neuen Filter gefunden, sieht das nicht klasse aus?",
  },
  {
    image: "10.jpg",
    description: "Goldsuche am Ende des Regenbogens",
  },
  {
    image: "11.jpg",
    description: "Tagesradtour mit den Geschwistern",
  },
  {
    image: "12.jpg",
    description: "#Wildlife #Papagei ",
  },
  {
    image: "13.jpg",
    description: "Imposanter Innenraum der Kirche",
  },
  {
    image: "14.jpg",
    description: "Sonnenuntergang mal anders",
  },
  {
    image: "15.jpg",
    description: "Unser Lieblingsrestaurant im Urlaub",
  },
  {
    image: "16.jpg",
    description: "Pyramiden sind eine unfassbare Leistung der Menschen",
  },
  {
    image: "17.jpg",
    description: "Bester Soulmusiker weit und breit",
  },
  {
    image: "18.jpg",
    description: "Autorennen in der Wüste, harte Bedingungen",
  },
  {
    image: "19.jpg",
    description: "Segelhafen",
  },
  {
    image: "20.jpg",
    description: "Wanderung im stillgelegten Bergstollen",
  },
  {
    image: "21.jpg",
    description: "Aus dem Urlaub, Schloss am Bodensee Langenargen",
  },
  {
    image: "22.jpg",
    description: "Urplötzlich steht das Reh vor mir, tolle Situation",
  },
  {
    image: "23.jpg",
    description: "Letzter Abend, Sonnenuntergang am Meer",
  },
  {
    image: "24.jpg",
    description: "Eigene Stadt erneut erkundigen. Lange ist's her",
  },
  {
    image: "25.jpg",
    description: "Renovierung des Zimmers ist abgeschlossen",
  },
];

const COMMENTS = [
  "Nö, hübsch aber ist so meins.",
  "Holy Shit, ist das cool!",
  "Fantastisches Foto :)",
  "Ich will mehr davon!!!",
  "Gähn, gefällt mir nicht …",
  "Wow!",
  "BOAH, voll hübsch",
  "Miep, ich war hier <3",
  "Wuhuuuu Wer zuviel Zeit hat….",
  "Gefällt mir",
  "Toll",
  "Wow",
  "OMG",
];

const IMAGE_COMMENTS = [
  {
    image: "Biene.jpg",
    comments: [
      "Wow! Was für ein perfekter Schnappschuss",
      "Hammer Bild! Wie lange hast du auf die Biene gewartet?",
      "Richtig tolles Bild. Bienen sind extrem wichtige Tiere!",
      "Bitte mehr davon",
      "Du bist wirklich talentiert - zeig uns noch mehr deiner Fotos!",
      "Wie heißt die Blume? Schönes Foto",
      "Einfach nur wunderschön ",
      "Wahnsinn, die Ruhe im richtigen Moment den Auslöser zu drücken.. Respekt",
    ],
  },
  {
    image: "Frau Gitarre.jpg",
    comments: [
      "Was für ein Lied wurde gespielt?",
      "Cooler Style",
      "Schönes Foto, passt alles gut zusammen!",
      "Ist schon hübsch, wirkt mir persönlich aber etwas zu aufgesetzt",
      "Die Stiefel feier ich hart! Woher sind die?",
      "Ich wusste gar nicht, dass du Gitarre spielen kannst, voll cool!",
      "Gibst du auch Konzerte?",
      "Was soll man dazu sagen… HAMMER",
    ],
  },
  {
    image: "Frau und Kind.jpg",
    comments: [
      "Wohin wart ihr denn unterwegs?",
      "Die Landschaft im Hintergrund ist fantastisch",
      "Toll, wie die beiden  aufeinander im Style abgestimmt  sind",
      "Starkes Bild!",
      "Wahnsinnslandschaft… Was für Bäume sind das?",
      "Wo wurde das Bild aufgenommen?",
      "So ein hübsches Bild! Noch schöner wäre es nur, wenn beide lächeln würden",
      "Schniekes Bild. Bin nur etwas über die Liege im Hintergrund verwundert.",
    ],
  },
  {
    image: "Katze.jpg",
    comments: [
      "Ich liebe Tierfotos!",
      "Süßßßß! Ist das deine Katze?",
      "Auf was die Katze wohl gerade schaut?",
      "Knorkes Bild, ist das bei euch im Garten?",
      "Die Katze erinnert mich sehr an meine alte Katze. Schöne Kulisse",
      "Ich bin total Fan davon, dass  Katzen draußen sein dürfen!",
      "Wo hast du das aufgenommen? Toller Schuss",
      "Konntest du die Katze streicheln?",
    ],
  },
  {
    image: "Kinder Fußball.jpg",
    comments: [
      "Geiler Seitfallzieher",
      "Das Bild sieht episch aus",
      "Erinnert mich irgendwie an Zlatan",
      "Krasse artistische Einlage und was für ein Bild!",
      "Gänsehaut pur!",
      "Wo hast du das aufgenommen?",
      "Ist das Fußballfeld durch eine Überschwemmung unter Wasser?",
      "Top Bild.. Ob der TW das hält?",
    ],
  },
  {
    image: "Mann Frisbee.jpg",
    comments: [
      "Da bekomme ich direkt Lust Frisbee zu spielen",
      "Hahaha, die Abwurfposition sieht irgendwie lustig aus",
      "Guter Wurf!",
      "Das ist im Park bei mir um die Ecke",
      "Körperspannung 1A",
      "Ich glaube, dass die Frisbee nicht trifft",
      "Perfektes Timing",
      "Kennt sich jemand aus, ob das die korrekte Abwurftechnik ist?",
    ],
  },
  {
    image: "Nachtschaukel.jpg",
    comments: [
      "Sternenhimmel, einfach Wahnsinn",
      "Ich will auch schaukeln ",
      "Was für'ne Idee, darauf wäre ich niemals gekommen",
      "Wo kann man den Sternenhimmel so klar sehen? ",
      "Ich bin hin und weg!!",
      "Das werde ich glatt neidisch (:",
      "Sprachlos",
      "Holy COW! Wunderschön",
    ],
  },
  {
    image: "Riesenrad.jpg",
    comments: [
      "Bei Nacht wirkt das Riesenrad ganz anders. ",
      "Damit möchte ich gerne fahren",
      "Auf welchem Rummel steht das Rad?",
      "Der Himmel sieht auch Hammer aus!",
      "Cooles Foto",
      "Das Rot des Sonnenuntergangs macht das Foto erstklassig",
      "Naja, finde ich nicht so besonders.",
      "Geilo!!",
    ],
  },
  {
    image: "Porsche.jpg",
    comments: [
      "Das ist mein Traumauto",
      "Gönnung, nehme ich auch ;)",
      "Die Gelben lichter sehen aus wie Augen",
      "Krasses Auto, würde ich in in silber aber besser finden",
      "Porsche, immer geil <3",
      "Wie schnell fährt das Auto?",
      "Arrrr, dieses weinrot soooo hübsch",
      "Sieht gut aus, aber braucht man sowas?",
    ],
  },
];

const TextPlural = (props) =>
  `${props.value} ${props.value == 1 ? props.singular : props.plural}`;

const App = () => {
  const [posts, setPosts] = useState([]);

  const [authors, setAuthors] = useState([
    {
      name: "Lobo",
      avatar: "BotAvatare/Lobo.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Loboline",
      avatar: "BotAvatare/Loboline.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Patrik",
      avatar: "BotAvatare/yawning-face_1f971.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Nane",
      avatar:
        "BotAvatare/victory-hand_medium-light-skin-tone_270c-1f3fc_1f3fc.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Dustbunny",
      avatar: "BotAvatare/speak-no-evil-monkey_1f64a.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Nadege",
      avatar: "BotAvatare/smiling-face-with-sunglasses_1f60e.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Cyril",
      avatar: "BotAvatare/smiling-cat-with-heart-eyes_1f63b.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Isabella",
      avatar: "BotAvatare/sleepy-face_1f62a.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Mats",
      avatar: "BotAvatare/see-no-evil-monkey_1f648.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Lani",
      avatar: "BotAvatare/pleading-face_1f97a.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Sonic",
      avatar: "BotAvatare/partying-face_1f973.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Ole",
      avatar: "BotAvatare/ogre_1f479.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Gabo",
      avatar: "BotAvatare/nerd-face_1f913.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Saira",
      avatar: "BotAvatare/monkey_1f412.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Jacqi",
      avatar: "BotAvatare/hear-no-evil-monkey_1f649.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Aenne",
      avatar: "BotAvatare/gorilla_1f98d.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Mine",
      avatar: "BotAvatare/goblin_1f47a.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Rupert",
      avatar: "BotAvatare/fox_1f98a.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Vera",
      avatar: "BotAvatare/flushed-face_1f633.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Fee",
      avatar: "BotAvatare/flexed-biceps_light-skin-tone_1f4aa-1f3fb_1f3fb.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Animallover",
      avatar: "BotAvatare/flexed-biceps_dark-skin-tone_1f4aa-1f3ff_1f3ff.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "DJAnton",
      avatar: "BotAvatare/flexed-biceps_1f4aa.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Arnd",
      avatar: "BotAvatare/face-with-steam-from-nose_1f624.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Sasuke",
      avatar: "BotAvatare/face-with-monocle_1f9d0.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Naruto",
      avatar: "BotAvatare/face-screaming-in-fear_1f631.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Spongebob",
      avatar: "BotAvatare/dog-face_1f436.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Mardonner",
      avatar: "BotAvatare/disguised-face_1f978.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "EarthMother",
      avatar: "BotAvatare/cowboy-hat-face_1f920.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Salamandrine",
      avatar: "BotAvatare/alien-monster_1f47e.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
    {
      name: "Genie",
      avatar: "BotAvatare/alien_1f47d.png",

      isFollowed: false,
      followers: Math.floor(Math.random() * 10000),
      isOwn: false,
    },
  ]);

  const [visibleUpload, setVisibleUpload] = useState(false);

  const botImages = useRef();

  const init = () => {
    setPosts([
      {
        uuid: 1,
        author: authors[0],
        image: "Bilder/Lobo _ Loboline.JPG",
        description: "Start unserer Weltreise: Letztes Foto aus Berlin! 😊",
        likes: 12,
        shares: 2,
        comments: [
          {
            author: authors[7],
            content:
              "Ich wünsche euch ganz viel Spaß – bringt mir ein Souvenir mit 😉",
            created: Date.now(),
          },
          {
            author: authors[8],
            content:
              "Schick mir bitte eine Postkarte aus Amerika. Euch eine tolle Reise",
            created: Date.now(),
          },
        ],

        showComments: false,
        showCommentForm: false,
        isLiked: false,
        isShare: false,
        isOwn: false,
      },
      {
        uuid: 2,
        author: authors[1],
        image: "Bilder/Lobo _ Loboline2.JPG",
        description:
          "Erste Station unserer Reise sind die Pyramiden in Ägypten. Lobo wollte sogar hochklettern – ist aber verboten (:",
        likes: 12,
        shares: 2,
        comments: [
          {
            author: authors[9],
            content:
              "Tolles Foto! Wie hoch sind die Pyramiden? Es sieht sehr heiß aus",
            created: Date.now(),
          },
          {
            author: authors[7],
            content:
              "Das sieht aber richtig gut aus. Vergesst mein Souvenir nicht :D",
            created: Date.now(),
          },
        ],

        showComments: false,
        showCommentForm: false,
        isLiked: false,
        isShare: false,
        isOwn: false,
      },
    ]);

    botImages.data = [...IMAGES_BOT].shuffle();
  };

  const AI = (ignoreOwner) => {
    setPosts((posts) => {
      const hasOwnPosts = posts.some((post) => post.isOwn);

      if (hasOwnPosts && !ignoreOwner) {
        let followers = authors[authors.length - 1].followers;
        authors[authors.length - 1].followers += Math.floor(Math.random() * 4);
        if (authors[authors.length - 1].followers != followers)
          message.success("Neue Follower");
      }

      setAuthors((authors) => {
        authors.forEach((author) => {
          if (author.isOwn) return;
          author.followers += Math.floor(Math.random() * 60);
        });

        return [...authors];
      });

      if (Math.random() > 0.5) {
        if (botImages.data.length == 0)
          botImages.data = [...IMAGES_BOT].shuffle();
        const randomBotImage = botImages.data.pop();

        posts.push({
          uuid: Date.now(),
          author: authors.sample(authors.length - 1),
          image: "BotBilder/" + randomBotImage.image,
          description: randomBotImage.description,
          likes: 0,
          shares: 0,
          comments: [],

          showComments: false,
          showCommentForm: false,
          isLiked: false,
          isShare: false,
          isOwn: false,
        });
      }

      posts.forEach((post) => {
        if (post.isOwn && ignoreOwner) return;

        let likes = post.likes;
        post.likes += Math.floor(Math.random() * 3);

        if (post.isOwn && likes != post.likes) message.success("Neue ❤️");

        let shares = post.shares;
        post.shares += Math.floor(Math.random() * 3);

        if (post.isOwn && shares != post.shares)
          message.success("Bild geteilt");

        let comments = post.comments.length;
        if (Math.random() > 0.5) {
          let nextComment = COMMENTS.sample();

          if (post.isOwn) {
            const imageComment = IMAGE_COMMENTS.filter(
              (imageComment) => "Bilder/" + imageComment.image == post.image
            );

            if (
              imageComment &&
              post.imageComment < imageComment[0].comments.length
            ) {
              nextComment = imageComment[0].comments[post.imageComment];
              post.imageComment++;
            }
          }

          post.comments.push({
            author: authors.sample(authors.length - 1),
            content: nextComment,
            created: Date.now(),
          });
        }

        if (post.isOwn && comments != post.comments.length)
          message.success("Neue Kommentare");
      });
      return [...posts];
    });
  };

  useEffect(init, []);

  let countLikes = posts.reduce(
    (total, post) => (!post.isOwn ? total : total + post.likes),
    0
  );
  let countComments = posts.reduce(
    (total, post) => (!post.isOwn ? total : total + post.comments.length),
    0
  );
  let countShares = posts.reduce(
    (total, post) => (!post.isOwn ? total : total + post.shares),
    0
  );
  let countFollowers = authors.reduce(
    (total, author) => (!author.isOwn ? total : total + author.followers),
    0
  );

  return (
    <>
      <div className="phone-container">
        <div className="phone-top"></div>
        <div className="phone-screen">
          <div className="phone-display">
            <div className="phone-bar">
              <div className="phone-time">10:30</div>
              <div className="phone-infos">
                <img src="bar-chart-fill.svg" />
                <img src="wifi-2.svg" />
                <img src="battery-half.svg" />
              </div>
            </div>
            <div className="phone-content">
              <Register
                onFinish={(values) => {
                  authors.push({
                    name: values.name,
                    avatar: values.avatar,

                    followers: 0,
                    isOwn: true,
                  });

                  setAuthors([...authors]);

                  window.setInterval(
                    () => AI(values.group == "a" ? true : false),
                    MIN_AI_TIME
                  );

                  notification.info({
                    message: `Hallo ${values.name}`,
                    description: "Willkommen bei Instalobo",
                    placement: "top",
                  });
                }}
              />
              <Upload
                onCancel={() => {
                  setVisibleUpload(false);
                }}
                onFinish={(values) => {
                  setVisibleUpload(false);

                  posts.push({
                    uuid: Date.now(),
                    author: authors[authors.length - 1],
                    image: values.image,
                    description: values.description,
                    likes: 0,
                    shares: 0,
                    comments: [],

                    showComments: false,
                    showCommentForm: false,
                    isLiked: false,
                    isShare: false,
                    isOwn: true,

                    imageComment: 0,
                  });

                  setPosts([...posts]);
                }}
                visible={visibleUpload}
              />
              <Space direction="vertical">
                {posts.map((post, key) => (
                  <div key={key}>
                    <Space>
                      <Avatar size={48} src={post.author.avatar} />
                      <Space>
                        <div>
                          <Text strong>{post.author.name}</Text>
                          <div>
                            <TextPlural
                              value={post.author.followers}
                              singular="folgt"
                              plural="folgen"
                            />{" "}
                            dem Autor
                          </div>
                        </div>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            post.author.isFollowed = !post.author.isFollowed;
                            if (post.author.isFollowed) {
                              message.success(`Du folgst ${post.author.name}`);
                              post.author.followers++;
                            } else {
                              message.info(
                                `Du folgst nicht mehr ${post.author.name}`
                              );
                              post.author.followers--;
                            }

                            setAuthors([...authors]);
                          }}
                        >
                          {!post.isOwn && (
                            <>
                              {!post.author.isFollowed && <span>Folgen</span>}
                              {post.author.isFollowed && (
                                <span>Nicht Folgen</span>
                              )}
                            </>
                          )}
                        </Button>
                      </Space>
                    </Space>
                    <Image src={post.image} />
                    <Space style={{ fontSize: "2em" }}>
                      <a
                        onClick={() => {
                          post.isLiked = !post.isLiked;
                          if (post.isLiked) post.likes++;
                          else post.likes--;
                          setPosts([...posts]);
                        }}
                      >
                        {!post.isLiked && <HeartOutlined />}
                        {post.isLiked && (
                          <HeartFilled style={{ color: "red" }} />
                        )}
                      </a>
                      <a
                        onClick={() => {
                          post.showCommentForm = !post.showCommentForm;
                          setPosts([...posts]);
                        }}
                      >
                        <MessageOutlined />
                      </a>
                      {!post.isOwn && (
                        <a
                          onClick={() => {
                            post.isShare = !post.isShare;
                            if (post.isShare) {
                              post.shares++;
                              message.success(`Du hast den Post geteilt!`);
                            } else {
                              post.shares--;
                              message.info(`Du teilst den Post nicht mehr`);
                            }
                            setPosts([...posts]);
                          }}
                        >
                          <SendOutlined
                            style={{ color: post.isShare ? "red" : "" }}
                          />
                        </a>
                      )}
                    </Space>
                    <div>
                      <Text strong>
                        <TextPlural
                          value={post.likes}
                          singular="mag"
                          plural="mögen"
                        />{" "}
                        das Bild,{" "}
                        <TextPlural
                          value={post.shares}
                          singular="hat"
                          plural="haben"
                        />{" "}
                        das Bild geteilt
                      </Text>
                    </div>
                    <div>
                      <Text strong>{post.author.name}</Text>&nbsp;
                      <span>{post.description}</span>
                    </div>
                    <a
                      onClick={() => {
                        post.showComments = !post.showComments;
                        setPosts([...posts]);
                      }}
                    >
                      <TextPlural
                        value={post.comments.length}
                        singular="Kommentar"
                        plural="Kommentare"
                      />
                    </a>

                    {post.showCommentForm && (
                      <Form
                        onFinish={(values) => {
                          post.comments.push({
                            author: authors[authors.length - 1],
                            content: values.comment,
                            created: Date.now(),
                          });

                          post.showCommentForm = false;
                          post.showComments = true;

                          setPosts([...posts]);
                        }}
                      >
                        <Form.Item
                          name="comment"
                          rules={[
                            {
                              required: true,
                              message: "Bitte Kommentar eingeben!",
                            },
                          ]}
                        >
                          <Input.TextArea
                            rows={3}
                            placeholder="Kommentar"
                            size="small"
                          />
                        </Form.Item>
                        <div className="right">
                          <Button type="primary" size="small" htmlType="submit">
                            Kommentieren
                          </Button>
                        </div>
                      </Form>
                    )}

                    {post.showComments && (
                      <List
                        dataSource={post.comments}
                        renderItem={(comment, key) => (
                          <Comment
                            key={key}
                            author={comment.author.name}
                            avatar={<Avatar src={comment.author.avatar} />}
                            content={<p>{comment.content}</p>}
                            datetime={
                              <Tooltip
                                title={moment(comment.created).format(
                                  "HH:mm:ss"
                                )}
                              >
                                <span>{moment(comment.created).fromNow()}</span>
                              </Tooltip>
                            }
                          />
                        )}
                      />
                    )}

                    <Divider />
                  </div>
                ))}
              </Space>
            </div>
            <div className="phone-nav">
              <Badge count={countLikes}>
                <Tooltip title={<>{countLikes} mal gemocht</>}>
                  <HeartFilled
                    style={{
                      fontSize: "2rem",
                      color: countLikes > 0 ? "red" : "",
                    }}
                  />
                </Tooltip>
              </Badge>
              <Badge count={countComments}>
                <Tooltip title={<>{countComments} mal kommentiert</>}>
                  <MessageOutlined
                    style={{
                      fontSize: "2rem",
                      color: countComments > 0 ? "red" : "",
                    }}
                  />
                </Tooltip>
              </Badge>

              <a
                onClick={() => {
                  setVisibleUpload(true);
                }}
              >
                <Tooltip title="Bild hochladen">
                  <UploadOutlined />
                </Tooltip>
              </a>

              <Badge count={countShares}>
                <Tooltip title={<>{countShares} mal geteilt</>}>
                  <SendOutlined
                    style={{
                      fontSize: "2rem",
                      color: countShares > 0 ? "red" : "",
                    }}
                  />
                </Tooltip>
              </Badge>
              <Badge count={countFollowers}>
                <Tooltip
                  title={
                    <>
                      <TextPlural
                        value={countFollowers}
                        singular="folgt"
                        plural="folgen"
                      />{" "}
                      dir
                    </>
                  }
                >
                  <UsergroupAddOutlined
                    style={{
                      fontSize: "2rem",
                      color: countFollowers > 0 ? "red" : "",
                    }}
                  />
                </Tooltip>
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Register = (props) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish = () => {
    const values = form.getFieldsValue();

    props.onFinish(values);
    form.resetFields();
    setVisible(false);
  };

  return (
    <Modal
      width={460}
      title="Registriere dich, um die Fotos deiner Freunde zu sehen"
      visible={visible}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Weiter
        </Button>,
      ]}
    >
      <Space size={"large"}>
        <img src="/logo.png" alt="" />
        <div>
          <Title>CBB Instalobo</Title>
          <div>© 2024 Computer Bildung Berlin</div>
        </div>
      </Space>
      <Divider />
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Form.Item
          label="E-Mail"
          name="mail"
          rules={[
            {
              required: true,
              message: "Bitte E-Mail eingeben!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Bitte Benutzername eingeben!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Gruppe"
          name="group"
          rules={[
            {
              required: true,
              message: "Bitte Gruppe wählen!",
            },
          ]}
        >
          <Select>
            <Option value="a">A</Option>
            <Option value="b">B</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Passwort"
          name="password"
          rules={[
            {
              required: true,
              message: "Bitte Passwort eingeben!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Avatar"
          name="avatar"
          rules={[
            {
              required: true,
              message: "Bitte Avatar wählen!",
            },
          ]}
        >
          <Radio.Group buttonStyle="solid" size="large">
            {AVATARS.shuffle()
              .slice(0, 4)
              .map((avatar, key) => (
                <Radio.Button key={avatar} value={"Avatare/" + avatar}>
                  <img height="32" src={"Avatare/" + avatar} />
                </Radio.Button>
              ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Text type="secondary">
            Indem du dich registrierst, stimmst du unseren Nutzungsbedingungen
            zu. In unserer Datenrichtlinie erfährst du, wie wir deine Daten
            erheben, verwenden und teilen. Unsere Cookie-Richtlinie erklärt, wie
            wir Cookies und ähnliche Technologien verwenden.
          </Text>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Upload = (props) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();

    props.onCancel();
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish = () => {
    const values = form.getFieldsValue();

    props.onFinish(values);
    form.resetFields();
  };

  return (
    <Modal
      transitionName=""
      width={460}
      title="Bild posten"
      visible={props.visible}
      footer={[
        <Button onClick={handleCancel}>Abbrechen</Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Weiter
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Bild"
          name="image"
          rules={[
            {
              required: true,
              message: "Bitte Bild eingeben!",
            },
          ]}
        >
          <Radio.Group size="large">
            {IMAGES.map((image, key) => (
              <Radio.Button key={image} value={"Bilder/" + image}>
                <img height="32" src={"Bilder/" + image} />
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Beschreibung"
          name="description"
          rules={[
            {
              required: true,
              message: "Bitte Beschreibung eingeben!",
            },
          ]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default App;
